<template>
  <div>
    <section class="main">
      <div class="hero parallax"></div>

      <article>
        <h2>{{ currentRouteName }}</h2>

        <p class="intro">
          Since its founding, GammaTeam Security has distinguished itself by its total commitment to security professionalism. Here is what some of our customers have to say about us.
        </p>

        <testimonials></testimonials>

      </article>
    </section>
  </div>
</template>

<script>
import testimonials from '@/components/testimonials'
export default {
  name: 'Testimonials',
  components: {
    testimonials
  }
}
</script>

<style scoped>

</style>
